.structure {
  width: 70%;
  margin: auto;
  padding-top: 3%;
  /* padding: 3%; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; 

}
.number{
  font-weight: 500;
}
.no-underline-link {
  color: black;
  text-decoration: none;
  
}

.contact-email {
  border-radius: 8px;
  width: 30%;
  border: 1px solid #FF8C00;
  padding: 3%;
  margin-bottom: 3%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box; 
  overflow: hidden; 
}

.contact-email:hover {
  border: 2px solid darkorange;
}

.email-href {
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  /* font-weight: 500; */
  word-wrap: break-word; 
}

.logo11 {
  margin-bottom: 2%;
}

h3 {
  margin-top: 2%;
  margin-bottom: 2%;
}

.mapContainer {
  width: 100%;
  border-radius: 8px;
  margin: 3% auto;
}

.mapImage {
  width: 100%;
  border-radius: 8px;
}

@media (max-width: 1024px) {
  .structure {
      flex-direction: column;
      align-items: center;
  }

  .contact-email {
      width: 80%;
  }

  .mapContainer {
      width: 100%;
  }

  .mapImage {
      height: auto;
      
  }
}


@media (max-width: 768px) {
  .structure {
      width: 90%;
      padding: 2%;
  }

  .contact-email {
      width: 100%;
  }
}
h5:hover{
    color: #EB4D1A;
    
}
.cardc {
  background-color: #fff;
  border-radius: 9px;
  width: 16rem;
  height: 7rem;
  margin-top: 2%;
  margin-bottom: 25px;
  padding: 2%;
  display: flex;
  flex-direction: column;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1), 
              -4px -4px 10px rgba(255, 255, 255, 0.9), 
              4px 4px 20px rgba(0, 0, 0, 0.05), 
              -4px -4px 20px rgba(255, 255, 255, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-bottom: 10px solid #EB4D1A; 
  border-left: 1px solid #EB4D1A;
  border-right: 1px solid #EB4D1A;
  border-top: 1px solid #EB4D1A;
}

.cardc:hover {
  border: 0px solid #EB4D1A;
  box-shadow: 16px 16px 80px rgba(0, 0, 0, 0.2), 
              -16px -16px 80px rgba(255, 255, 255, 0.9), 
              16px 16px 80px rgba(0, 0, 0, 0.1), 
              -16px -16px 80px rgba(255, 255, 255, 0.7);
  transform: translateY(-10px);
}

.upper {
  flex: 1; /* 2 parts */
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.lower {
  flex: 1; /* 3 parts */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  text-align: center;
}

.logo1 {
  width: 37px;
  height: 37px;
  margin-right: 10px;
}

.structure {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  padding-top: 3%;
  width: 70%;
}

.loc {
  font-size: 12px !important;
  line-height: 1rem !important;
}
